<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        {{ title }}{{ hotel.Name }}飯店房型
      </h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="back"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button>
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-flow-row gap-2">
            <div class="grid grid-flow-row gap-1">
              <label>房型名稱</label>
              <vxe-input
                placeholder="房型名稱"
                class="w-full"
                v-model.trim="validate.Name.$model"
                :class="{ 'bg-red-200': validate.Name.$error }"
              ></vxe-input>
              <template v-if="validate.Name.$error">
                <div
                  v-for="(error, index) in validate.Name.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>備註</label>
              <vxe-textarea
                placeholder=""
                class="w-full"
                v-model="form.formData.Remark"
              ></vxe-textarea>
            </div>
          </div>
        </div>

        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 flex-col"
          >
            <h2 class="font-medium text-base mr-auto">房型照片</h2>
            <!-- <div class="dropdown ml-auto">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;">
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-300"
                />
              </a>
              <div class="dropdown-box w-40">
                <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <PlusIcon class="w-4 h-4 mr-2" /> Add Category
                  </a>
                  <a
                    href=""
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  >
                    <SettingsIcon class="w-4 h-4 mr-2" />
                    Settings
                  </a>
                </div>
              </div>
            </div> -->
          </div>
          <div class="w-full pt-2">
            <slider-editor
              ref="sliderEditor"
              :navigation="true"
              :width="sliderWidth"
              :filePostAction="uploadAction"
              :formOptions="pictureFormOptions"
              v-model="form.formData.Gallery"
              @add="onSliderEdit"
              @edit="onSliderEdit"
              @save="onSliderSave"
              @remove="onSliderRemove"
            />
          </div>
        </div>

        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">房型簡介</h2>
          </div>
          <div>
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="form.formData.Introduction.Content"
                      :editor="classicEditor"
                      :config="simpleEditorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">房型規格說明</h2>
          </div>
          <div>
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="form.formData.Specification.Content"
                      :editor="classicEditor"
                      :config="simpleEditorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">注意事項</h2>
          </div>
          <div>
            <div class="wysiwyg-box">
              <div class="container-fluid container-xl">
                <div class="row">
                  <div class="col">
                    <CKEditor
                      v-model="form.formData.Notice.Content"
                      :editor="classicEditor"
                      :config="simpleEditorConfig"
                    />
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="back"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button>
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  Model,
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import formatDate from "xe-utils/toDateString";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import VueTagsInput from "@sipec/vue3-tags-input";
import { CustomFigureAttributes } from "@/global-components/ckeditor/custom";

import {
  required,
  //   minLength,
  //   maxLength,
  //   email,
  //   url,
  //   integer,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import { deepEqual } from "fast-equals";
import _ from "lodash";
import { v1 as uuidv1 } from "uuid";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
export default defineComponent({
  components: {
    FileUploader,
    VueTagsInput,
    SliderEditor,
    SelectBox,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const model = CloudFun.current?.model;
    const canEdit = true;
    const tag = ref("");
    const tags = ref([]);
    const hasChanged = ref(false);
    const files = ref([]);
    const tagsItems = ref([]);
    const original = reactive({ formData: {} });
    const sliderEditor = ref({});
    const city = ref([]);
    const currentCity = ref([]);
    const hotel = ref({});
    const data = ref({});

    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });
    const form = reactive({
      formData: {
        Id: 0,
        Name: null,
        HotelId: route.query.hotelId,
        Additional: null,
        Notice: {
          Content: "",
        },
        Introduction: {
          Content: "",
        },
        Remark: null,
        Gallery: {
          Pictures: null,
        },
        Specification: {
          Content: "",
        },
      },
    });

    const formRules = {
      Name: {
        required,
        // minLength: minLength(2),
      },
      //   },
    };
    const validate = useVuelidate(formRules, toRefs(form.formData));
    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" },
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "清除" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
      },
    };

    const title = computed(() => {
      return form.formData.Id === 0 ? "新增" : "編輯";
    });

    function schemaCustomization(editor) {
        editor.model.schema.extend( '$block', {
            allowAttributes: 'classList'
        });
        editor.conversion.attributeToAttribute( {
            model: {
                key: 'classList'
            },
            view: {
                key: 'class'
            }
        } );
    }

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin, CustomFigureAttributes],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold custom-big" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold custom-default" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      fontSize: {
				options: [
					{ title:'特小', model: 'custom-tiny-tool', view: { name: 'span', classes: 'custom-tiny' } },
					{ title:'小', model: 'custom-small-tool', view: { name: 'span', classes: 'custom-small' } },
					{ title:'預設', model: 'custom-default-tool', view: { name: 'span', classes: 'custom-default' } },
					{ title:'大', model: 'custom-big-tool', view: { name: 'span', classes: 'custom-big' } },
					{ title:'特大', model: 'custom-huge-tool', view: { name: 'span', classes: 'custom-huge' } },
				],
			},
    };

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;
    });

    // const title = computed(() => {
    //   return form.model === "insert" ? "新增" : "編輯";
    // });

    onMounted(() => {
      if (route.query) {
        model?.dispatch("hotel/find", route.query.hotelId).then(
          (payload) => {
            hotel.value = payload;
          },
          (failure) => {
            console.log("failure", failure);
          }
        );

        if (route.query.id) {
          model?.dispatch("hotelRoom/find", route.query.id).then(
            (payload) => {
              console.log("payload", JSON.stringify(payload));
              Object.assign(form.formData, payload);
              original.formData = _.cloneDeep(form.formData);

              console.log("payload", payload);
              console.log("form.formData", form.formData);

              sliderEditor.value.reload(form.formData.Gallery.Pictures);
              nextTick(() => {
                hasChanged.value = false;
              });
            },
            (failure) => {
              console.log("failure", failure);
            }
          );
        }
      }
    });

    // modal 執行 新增or修改
    const save = () => {
      validate.value.$touch();
      console.log(validate.value);
      if (validate.value.$invalid) return;
      if (form.formData.Id === 0) params.insertRows = [form.formData];
      else {
        const postData = _.cloneDeep(form.formData);
        if (form.formData.Gallery.length > 0) {
          postData.Gallery = form.formData.Gallery[0];
        }
        params.updateRows = [postData];
      }

      model
        .dispatch("hotelRoom/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            CloudFun.send("info", {
              subject: form.formData.Id == 0 ? "新增成功" : "更新成功",
              content:
                form.formData.Id == 0 ? "飯店資料新增完成" : "飯店資料更新完成",
            });
            hasChanged.value = false;
            router.back();
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();

      e.preventDefault();
    };

    return {
      classicEditor,
      simpleEditorConfig,
      form,
      canEdit,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      tag,
      tags,
      validate,
      save,
      router,
      route,
      files,
      original,
      pictureFormOptions,
      sliderEditor,
      params,
      city,
      hotel,
      currentCity,
      title,
      data,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    onSliderEdit(image, callback) {
      image.GalleryId = this.form.formData.PhotoGalleryId;
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params, callback) {
      await this.$model.dispatch("picture/save", params).then(
        () => {
          debugger;
          this.$model
            .dispatch("pictureGallery/find", this.form.formData.PhotoGalleryId)
            .then(
              (entity) => {
                if (entity.Pictures)
                  entity.Pictures = entity.Pictures.sort(
                    (a, b) => a.Ordinal - b.Ordinal
                  );
                this.$refs.sliderEditor.reload(
                  entity.Pictures.sort((a, b) => a.Ordinal - b.Ordinal)
                );
              },
              (reason) => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason,
                });
              }
            );
        },
        (reason) => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
  },
});
</script>

<style scoped></style>
